export class NuCustomError extends Error {
    code;

    constructor({
      code,
      message,
      error,
    }) {
      super();

      if (error && error instanceof Error) {
        if (!code) throw new Error('code is required');
        if (!message) throw new Error('message is required');

        this.code = code;
        this.message = `[NuCustomError] ${message}`;

        if (error?.message) {
          this.message += `\n${error.message}`;
        }
        return;
      }

      if (!code) throw new Error('code is required');
      if (!message) throw new Error('message is required');

      this.code = code;
      this.message = message;
    }
}
