import * as Sentry from '@sentry/react';

export function init({ dsn, environment }) {
  if (!dsn) {
    console.warn(
      "process.env.SENTRY_DSN is not defined. Sentry canno't be started.",
    );
    return;
  }
  console.warn(
    'using dns', dsn,
  );
  Sentry.init({
    dsn,
    integrations: [Sentry.browserTracingIntegration()],
    environment,
    tracesSampleRate: 1.0,
  });
}
