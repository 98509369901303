import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

import { NuCustomError } from './nuCustomError';

export function track({
  error,
  tags,
  level,
  sendAllStatusCode,
}) {
  const UNAUTHORIZED_CODE = 401;
  const NOT_FOUND_CODE = 404;

  const captureError = () => {
    Sentry.captureException(error, scope => {
      if (tags) {
        Object.entries(tags).forEach(([tagName, tagValue]) => {
          if (tagName && tagValue) {
            scope.setTag(tagName, tagValue);
          }
        });
      }

      const fingerPrintKeys = [];
      if (tags) {
        fingerPrintKeys.push(...Object.values(tags));
      }

      fingerPrintKeys.push(error.message);
      scope.setFingerprint(fingerPrintKeys);

      // eslint-disable-next-line no-unused-expressions
      level && scope.setLevel(level);

      return scope;
    });
  };

  if (error instanceof Error) {
    if ('code' in error) {
      if (
        !sendAllStatusCode
        && (error.code === UNAUTHORIZED_CODE || error.code === NOT_FOUND_CODE)
      ) {
        return;
      }
      captureError(error);
      console.warn('Sending inner track to sentry');
    } else {
      const customErrorMessage = new Error(String(error));
      const customError = new NuCustomError({
        code: 500,
        message: customErrorMessage.message,
      });
      captureError(customError);
      console.warn('Sending inner track to sentry');
    }
  }
}

// eslint-disable-next-line no-unused-vars
export function FallbackComponent({ error, componentStack, resetError }) {
  return (
    <div>
      <h2>Something went wrong.</h2>
      <p>
        An error has occurred:
        {' '}
        {error.toString()}
      </p>
      <button type="button" onClick={resetError}>Try again</button>
    </div>
  );
}

FallbackComponent.propTypes = {
  componentStack: PropTypes.string,
  error: PropTypes.instanceOf(Error).isRequired,
  resetError: PropTypes.func.isRequired,
};

FallbackComponent.defaultProps = {
  componentStack: '',
};
