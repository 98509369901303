import { withProfiler } from '@sentry/react';

import { track, FallbackComponent } from './track';
import { init } from './init';

export const errorTracking = {
  track,
  init,
  withProfiler,
  FallbackComponent,
};
